import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService }    from '@ngx-translate/core';

@Pipe({
  name: 'hours'
})
export class HoursPipe implements PipeTransform {

  constructor(
    private translateService : TranslateService
  ) {}

  transform( value : number ) : string | number {
    if (value > 1) {
      return value + this.translateService.instant('GENERAL_SPACE.FIELD.HRS');
    }
    else if (value <= 1 && value > 0) {
      return value + this.translateService.instant('BOOKING_SPACE.HR');
    }
    else {
      return 0;
    }
  }
}
