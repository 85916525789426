import { Injectable }       from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ApiService }               from '../api/api.service';
import { CompanyIntegrationsModel } from '../companies/models/company.integrations';
import { ConfirmService }           from '../messages/confirm.service.ajs';
import { PurchaseOrderModel }       from '../../app/purchase-orders/models/purchase-order';
import { SessionServiceAjs }        from '../sessions/session.service.ajs';
import { VehicleModel }             from '../vehicles/models/vehicle';

@Injectable({
  providedIn: 'root'
})
export class GsfService {
  public title = this.translateService.instant('COMPANY_SPACE.INTEGRATIONS.GSF.GSF');

  constructor (
    private apiService      : ApiService,
    private confirmService  : ConfirmService,
    private sessionService  : SessionServiceAjs,
    private translateService: TranslateService
  ) {}

  activate () : ng.IPromise<any> {
    return this.confirmService.generic(this.translateService.instant('JS_SPACE.INTEGRATIONS.ACTIVATE', {
      name: this.title
    }))
    .then(() => this.apiService.get('/company_integration/add_gsf_car_parts'));
  }

  deactivate () : ng.IPromise<any> {
    return this.confirmService.generic(this.translateService.instant('JS_SPACE.CONFIRM.REMOVE_INTEGRATION', {
      name: this.title
    }))
    .then(() => this.apiService.get('/company_integration/remove_gsf_car_parts'));
  }

  getPortalUrl ( type : string, vehicle : VehicleModel, referenceId : string ) : ng.IPromise<string> {
    return this.apiService.post('/gsf_car_parts/request_session', {
      object_id           : referenceId,
      request_type        : type,
      vehicle_plate_number: vehicle.plate_number,
    })
    .then(response => response.RedirectUrl);
  }

  isGsfVendor ( order : PurchaseOrderModel ) : boolean {
    return this.sessionService.company().gsf_car_parts_settings.gsf_car_parts_vendor_id === order.vendor_id;
  }

  save ( settings : CompanyIntegrationsModel ) : ng.IPromise<CompanyIntegrationsModel> {
    return this.apiService.patch('/company_integrations', settings, 'company_integration');
  }

  sendOrder ( order : PurchaseOrderModel ) : ng.IPromise<any> {
    return this.apiService.get('/purchase_orders/send_order_to_gsf_car_parts', order.id);
  }
}