import { Injectable }       from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DEFAULT_LANGUAGE } from './internationalization.constants';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  _defaultLanguage = DEFAULT_LANGUAGE;

  constructor (
    private translateService : TranslateService
  ) {}

  /**
   * Once the session service gets upgraded, we need
   * to change this to use it instead of getting the
   * info from the session storage directly.
   */
  getSessionLanguage () : string {
    const session  = sessionStorage.session_object
      ? JSON.parse(sessionStorage.session_object)
      : {};

    return session ?
      ( session.company_settings?.language || this._defaultLanguage )
      : this._defaultLanguage;
  }

  init () : void {
    this.translateService.setDefaultLang(this._defaultLanguage);

    this.setLanguage();
  }

  setLanguage ( language = this.getSessionLanguage() ) : void {
    this.translateService.use(language);
  }
}