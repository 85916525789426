import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  styles: [ require('./action.component.scss') ],
  template: require('./remove.component.html')
})
export class RemoveComponent {
  @Input() record: any;

  @Output() onRemoveClick: EventEmitter<any> = new EventEmitter();
}
