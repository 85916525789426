import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService }    from '@ngx-translate/core';

@Pipe({
  name: 'invoiceType',
})
export class InvoiceTypePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) {}

  transform( value : string ) {
    const typeMap = {
      C: this.translateService.instant('INVOICE_TYPES.CREDIT'),
      I: this.translateService.instant('INVOICE_TYPES.INVOICE'),
      Q: this.translateService.instant('INVOICE_TYPES.QUOTE'),
      U: this.translateService.instant('INVOICE_TYPES.INTERNAL')
    };

    return typeMap[value] || this.translateService.instant('INVOICE_TYPES.INVOICE');
  }
}