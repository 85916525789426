import { Component, Inject, Input, OnInit } from '@angular/core';

import { InvoiceModel } from './models/invoice';

@Component({
  template: require('./invoice.reference.component.html')
})
export class InvoiceReferenceComponent implements OnInit {
  invoiceApi: any;

  @Input() record: InvoiceModel;

  constructor (
    @Inject('Invoice') private Invoice: any
  ) {}

  ngOnInit () {
    this.invoiceApi = new this.Invoice(this.record);
  }

  getReference () : number {
    return this.invoiceApi.status('open')
      ? this.record.job_card_number
      : this.record.invoice_number;
  }
}