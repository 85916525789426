import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService }    from '@ngx-translate/core';

@Pipe({
  name: 'loanStatus',
})
export class LoanCarScheduleStatusPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) {}

  transform( value : string ) {
    const typeMap = {
      P: this.translateService.instant('LOAN_STATUS_TYPES.PENDING'),
      A: this.translateService.instant('LOAN_STATUS_TYPES.ACTIVE'),
      C: this.translateService.instant('LOAN_STATUS_TYPES.RETURNED'),
      X: this.translateService.instant('LOAN_STATUS_TYPES.CANCELLED')
    };

    return typeMap[value] || this.translateService.instant('STATUS_TYPES.OPEN');
  }
}