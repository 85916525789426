import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService }    from '@ngx-translate/core';

@Pipe({
  name: 'customerPaymentStatus'
})
export class CustomerPaymentStatusPipe implements PipeTransform {

  constructor(
    private translateService : TranslateService
  ) {}

  transform( value : string ) : string {
    const typeMap = {
      C: this.translateService.instant('STATUS_TYPES.CLOSED'),
      O: this.translateService.instant('STATUS_TYPES.OPEN')
    };

    return typeMap[value] || this.translateService.instant('STATUS_TYPES.OPEN');
  }
}

