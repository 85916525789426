import { Component, Inject, Input, OnInit } from '@angular/core';
import { UIRouter }                         from '@uirouter/angularjs';

import { CustomerModel }  from '../customers/models/customer';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Table }          from '../table/types/table';
import { TranslateService } from '@ngx-translate/core';

@Component({
  template: require('./biller.lookup.component.html')
})
export class BillerLookupComponent implements OnInit {
  private _billers : Array<CustomerModel>;
  @Input() set billers ( value : Array<CustomerModel> ) {
    this._billers = value;
  }
  get billers () : Array<CustomerModel> {
    return this._billers;
  }

  @Input() isVendor : boolean;

  selectedBiller : CustomerModel;

  tableSettings : Table = {
    columns: [
      {
        classes: 'text-left',
        field  : 'display_name',
        header : {
          text: this.translateService.instant('COMPANY_SPACE.COMPANY')
        }
      }
    ],
    empty: {
      text: this.translateService.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INVOICES_FOUND')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    sorting: {
      enabled: false
    },
    static: true
  };

  constructor (
    private activeModal      : NgbActiveModal,
    private router           : UIRouter,
    private translateService : TranslateService
  ) {}

  dismiss () : void {
    this.activeModal.dismiss();
  }

  ngOnInit(): void {
    if ( this.isVendor ) {
      this.tableSettings.columns[0].field = 'company_name';
    }
  }

  onRowClick ( biller : CustomerModel ) {
    this.selectedBiller = biller.selected
    ? biller
    : null;
  }

  onRowDoubleClick ( biller : CustomerModel ) {
    this.selectedBiller = biller;

    this.select();
  }

  select () {
    if ( this.isVendor ) {
      this.router.stateService.go('app.apply-vendor-credits', {
        vendor_id: this.selectedBiller.id
      });
    } else {
      this.router.stateService.go('app.apply-credits', {
        customer_id: this.selectedBiller.id
      });
    }

    this.activeModal.close();
  }
}