import { NgModule } from '@angular/core';

import { TranslateConfigService } from './translate-config.service';

@NgModule({
  providers: [
    TranslateConfigService
  ]
})
export class InternationalizationModule {}
