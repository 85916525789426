import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  template: require('./product.actions.component.html')
})
export class ProductActionsComponent {
  @Input() record: any;

  @Output() onEditClick      : EventEmitter<any> = new EventEmitter();
  @Output() onActivateClick  : EventEmitter<any> = new EventEmitter();
}
