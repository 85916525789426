import { Inject, Injectable } from '@angular/core';

import { NgbModal }         from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { API }                                 from '../api/api.constant.ajs';
import { ApiService }                          from '../api/api.service';
import { CompanyIntegrationsModel }            from '../companies/models/company.integrations';
import { ConfirmService }                      from '../messages/confirm.service.ajs';
import { CustomerPaymentModel }                from '../customer-payments/models/customer-payment';
import { CustomerPaymentService }              from '../customer-payments/customer-payment.service.ajs';
import { InvoiceModel }                        from '../invoices/models/invoice';
import { SessionServiceAjs }                   from '../sessions/session.service.ajs';
import { TillpaymentsPaymentModel }            from './models/tillpayments.payment';
import { TillpaymentsTerminalModel }           from './models/tillpayments.terminal';
import { TillpaymentsTransactionComponent }    from './tillpayments.transaction.component';
import { TillpaymentsTransactionModel }        from './models/tillpayments.transaction';
import { TillpaymentsTransactionTypeModel }    from './models/tillpayments.transaction-type';

@Injectable({
  providedIn: 'root'
})
export class TillpaymentsService {

  private _apiSettings     = API;

  private _transactionStatuses = {
    completed : 'COMPLETED',
    cancelled : 'CANCELLED',
    failed    : 'FAILED',
    pending   : 'PENDING',
    processing: 'PROCESSING',
    declined  : 'DECLINED',
    success   : 'SUCCESS'
  };

  private companyService: any;

  public tableCacheKey  = 'tillpaymentsTerminals';

  constructor (
    private apiService            : ApiService,
    @Inject('Company')
    private Company               : any,
    private confirmService        : ConfirmService,
    private customerPaymentService: CustomerPaymentService,
    private modalService          : NgbModal,
    private sessionService        : SessionServiceAjs,
    private translateService      : TranslateService
  ) {
    this.companyService = new this.Company();
  }

  activateOnline () : ng.IPromise<boolean> {
    return this.confirmService.generic(this.translateService.instant('JS_SPACE.INTEGRATIONS.ACTIVATE', {
      name: this.translateService.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.TILLPAYMENTS_ONLINE')
    }))
    .then(() => this.apiService.get('/company_integration/add_tillpayments_online'));
  }

  activatePos () : ng.IPromise<boolean> {
    return this.apiService.get('/company_integration/add_tillpayments_eft');
  }

  createTransaction (
    terminal : TillpaymentsTerminalModel,
    payment  : any,
    amount   : number,
    type     : TillpaymentsTransactionTypeModel = 'invoice',
    invoice ?: InvoiceModel
  ) : ng.IPromise<any> {
    return this.apiService.post('/tillpayments/tillpayments_terminal_transaction', {
      object_id  : type === 'payment' ? payment.id : invoice.id,
      amount     : amount,
      object_type: type === 'payment' ? type : 'invoice',
      terminal_id: terminal.terminalId
    });
  }

  deactivateOnline () : ng.IPromise<boolean> {
    return this.confirmService.generic(this.translateService.instant('JS_SPACE.INTEGRATIONS.DEACTIVATE', {
      name: this.translateService.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.TILLPAYMENTS_ONLINE')
    }))
    .then(() => this.apiService.get('/company_integration/remove_tillpayments_online'));
  }

  deactivatePos () : ng.IPromise<boolean> {
    return this.confirmService.generic(this.translateService.instant('JS_SPACE.INTEGRATIONS.DEACTIVATE', {
      name: this.translateService.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.TERMINAL_PAYMENTS')
    }))
    .then(() => this.apiService.get('/company_integration/remove_tillpayments_eft'));
  }

  didTransactionFail ( transaction : TillpaymentsTransactionModel ) : boolean {
    return transaction.eft_receipt.payment_status === this._transactionStatuses.cancelled
      || transaction.eft_receipt.payment_status === this._transactionStatuses.failed
      || transaction.eft_receipt.payment_status === this._transactionStatuses.declined
      || transaction.status_code !== '201';
  }

  doesLostTerminalPaymentExist ( payment : CustomerPaymentModel ) : Promise<boolean> | ng.IPromise<boolean> {
    return ( payment.id
      && payment.tillpayments_terminal_payment_intent_id
      && this.customerPaymentService.isStatus(payment, 'open')
    ) ? this.apiService.get('/tillpayments/check_lost_transaction_status', payment.id)
        .then(response => response.status === 'SUCCESS')
      : Promise.resolve(false);
  }

  getDefaultUserTerminalId ( user = this.sessionService.user() ) : ng.IPromise<string> {
    return this.apiService.get('/users/get_terminal_id', user.id)
    .then(result => result.terminal_id);
  }

  getGatewayUrl ( invoice : InvoiceModel ) : ng.IPromise<string> {
    return this.apiService.get('/tillpayments/get_tillpayments_gateway_url', invoice.id)
    .then(( response : any ) => response.gateway_url);
  }

  getPayment ( invoice : InvoiceModel, token : string ) : ng.IPromise<TillpaymentsPaymentModel> {
    return this.apiService.get('/tillpayments/request_payment', [
      invoice.id,
      token
    ]);
  }

  getTerminals () : ng.IPromise<Array<TillpaymentsTerminalModel>> {
    return this.apiService.get('/tillpayments/get_tillpayments_terminal_type_list');
  }

  getTerminalDetails ( id : string ) : ng.IPromise<TillpaymentsTerminalModel> {
    return this.apiService.get('/tillpayments/get_tillpayments_terminal_details/', id);
  }

  getTransactionStatus ( transaction : TillpaymentsTransactionModel ) : ng.IPromise<TillpaymentsTransactionModel> {
    return this.apiService.get('/tillpayments/get_terminal_transaction_status', transaction.eft_receipt.id)
    .then(( response : any ) => {

      transaction.eft_receipt.payment_status = response.eft_receipt.payment_status;

      return transaction;
    });
  }

  hasSurcharges ( invoice : InvoiceModel ) : boolean {
    return !!invoice.tillpayments_surcharge_amount;
  }

  isPosReady ( settings : CompanyIntegrationsModel ) : boolean {
    return this.companyService.hasIntegration('tillpayments-pos')
      && !!settings.tillpayments_terminal_api_key
      && !!settings.tillpayments_terminal_merchant_id;
  }

  isTerminalConnected ( terminal : TillpaymentsTerminalModel ) {
    return terminal.status.connectivity === 'AVAILABLE';
  }

  isTransactionApproved ( transaction : TillpaymentsTransactionModel ) : boolean {
    return transaction.eft_receipt.payment_status === this._transactionStatuses.success;
  }

  openPaymentPortal ( payment : TillpaymentsPaymentModel, invoice : InvoiceModel ) : void {
    this.getGatewayUrl(invoice)
    .then(( url : string ) => {
      const form = document.createElement('form');

      form.action        = url;
      form.method        = 'POST';
      form.style.display = 'none';

      for ( const key in payment ) {
        if (payment.hasOwnProperty(key)) {
          const input = document.createElement('input');

          input.type  = 'hidden';
          input.name  = key;
          input.value = payment[key];

          form.appendChild(input);
        }
      }

      document.body.appendChild(form);

      form.submit();
    });
  }

  processSurchargeAndPayment ( payment : any ) : ng.IPromise<any> {
    return this.apiService.post('/tillpayments/process_surcharge_and_payment', {
      object_id  : payment.id,
      object_type: 'payment',
      type       : 'payment'
    });
  }

  processTransaction (
    payment      : any,
    amount       : number,
    type         : TillpaymentsTransactionTypeModel = 'invoice',
    terminal    ?: TillpaymentsTerminalModel,
    invoice     ?: InvoiceModel,
  ) : ng.IPromise<any> {
    return Promise.resolve()
    .then(() => {
      return terminal
        ? Promise.resolve(terminal)
        : this.getDefaultUserTerminalId()
        .then(( terminalId : string ) => {
          const settings = this.sessionService.company().tillpayments_settings;

          if (!terminalId && !settings.tillpayments_terminal_id) {
            return Promise.reject(this.translateService.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.NO_ACTIVE_TERMINALS'));
          }

          return this.getTerminalDetails(terminalId || settings.tillpayments_terminal_id);
        });
    })
    .then(( terminal : TillpaymentsTerminalModel ) => {
      return this.isTerminalConnected(terminal)
        ? Promise.resolve(terminal)
        : Promise.reject(this.translateService.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.NO_ACTIVE_TERMINALS'));
    })
    .then(( terminal : TillpaymentsTerminalModel ) => {
      const modal = this.modalService.open(TillpaymentsTransactionComponent);

      modal.componentInstance.amount   = amount;
      modal.componentInstance.payment  = payment;
      modal.componentInstance.type     = type;
      modal.componentInstance.terminal = terminal;

      if ( type !== 'payment' ) {
        modal.componentInstance.invoice  = invoice;
      }

      return modal.result;
    })
    .then(( transaction : TillpaymentsTransactionModel ) => {
      return this.apiService.post('/tillpayments/process_surcharge_and_payment',
        {
          object_id  : type === 'payment' ? payment.id : invoice.id,
          object_type: type === 'payment' ? type : 'invoice',
          type       : type
        }
      )
      .then(() => transaction);
    })
    .then(( response : TillpaymentsTransactionModel ) => response.eft_receipt);
  }

  saveSettings ( settings : CompanyIntegrationsModel ) : ng.IPromise<CompanyIntegrationsModel> {
    return this.apiService.patch('/company_integrations', settings , 'company_integration');
  }

  saveDefaultUserTerminal ( terminal : TillpaymentsTerminalModel, user = this.sessionService.user() ) : ng.IPromise<any> {
    return this.apiService.post('/users/update_terminal_id', {
      id         : user.id,
      terminal_id: terminal.terminalId
    });
  }

}