import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslateService }                 from '@ngx-translate/core';

@Component({
  selector   : 'wor-company-type',
  template: require('./company.company-type.component.html')
})
export class CompanyTypeComponent implements OnInit {
  @Input() record: any;

  className     : string;
  companyService: any;
  title         : string;

  constructor (
    @Inject('Company')
    public    Company        : any,
    private translateService : TranslateService
  ) {}

  ngOnInit() {
    this.companyService = new this.Company(this.record);

    if (this.companyService.isDealer() && !this.companyService.isBranchDealer()) {
      this.className = 'status bg-warning';
      this.title     = this.translateService.instant('JS_SPACE.DEALER.DEALER');
    }
    else if (this.companyService.isDealer() && this.companyService.isBranchDealer()) {
      this.className = 'status bg-success';
      this.title     = this.translateService.instant('JS_SPACE.DEALER.HEAD_OFFICE');
    }
    else if (this.companyService.isBranchStore()) {
      this.className = 'status bg-info';
      this.title     = this.translateService.instant('JS_SPACE.DEALER.BRANCH');
    }
  }
}