import {
  AfterViewInit,
  Component,
  Input,
  ViewChild
}                           from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector   : '[worCopyToClipboard]',
  styles: [ require('./copy-to-clipboard.component.scss') ],
  template: require('./copy-to-clipboard.component.html')
})
export class CopyToClipboardDirective implements AfterViewInit {
  @Input('html') html;

  @ViewChild('content') content;

  icon    = 'fa-files-o';
  title   = this.translateService.instant('GENERAL_SPACE.TITLE.COPY_TO_CLIPBOARD');
  timeout = 1000;

  constructor (
    private translateService: TranslateService
  ) {}

  copy () : void {
    const text = this.content.nativeElement.textContent || this.content.nativeElement.innerText;

    navigator.clipboard.writeText(text)
    .then(() => {
      this.icon  = 'fa-check';
      this.title = this.translateService.instant('GENERAL_SPACE.TITLE.COPIED');

      setTimeout(() => {
        this.icon  = 'fa-files-o';
        this.title = this.translateService.instant('GENERAL_SPACE.TITLE.COPY_TO_CLIPBOARD');
      }, this.timeout);
    })
    .catch(err => {
      console.error('Failed to copy text:', err);
    });
  }

  ngAfterViewInit () : void {
    if ( this.html ) {
      this.content.nativeElement.insertAdjacentHTML('afterbegin', this.html);
    }
  }
}
