import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  template: require('./vendor.actions.component.html')
})
export class VendorActionsComponent {
  @Input() record: any;

  @Output() onEditClick    : EventEmitter<any> = new EventEmitter();
  @Output() onPaymentClick : EventEmitter<any> = new EventEmitter();
  @Output() onSMSClick     : EventEmitter<any> = new EventEmitter();
  @Output() onEmailClick   : EventEmitter<any> = new EventEmitter();
  @Output() onActivateClick: EventEmitter<any> = new EventEmitter();
}
