import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { EditComponent }      from '../table/cell-components/actions/edit.component';
import { PartstechService }   from './partstech.service';
import { PurchaseOrderModel } from '../../app/purchase-orders/models/purchase-order';
import { TableQueryModel }    from '../../app-customer/table/models/table-query';

@Component({
  selector   : 'wor-partstech-orders',
  template: require('./partstech.orders.component.html')
})
export class PartstechOrdersComponent {
  @Input() order  : PurchaseOrderModel;
  @Input() orders : Array<PurchaseOrderModel>;

  tableSettings = {
    columns: [
      {
        field : 'order_number',
        header: {
          text: this.translateService.instant('PURCHASE_ORDER_SPACE.ORDER_NUMBER')
        }
      },
      {
        field : 'vendor_name',
        header: {
          text: this.translateService.instant('VENDOR_SPACE.SUPPLIER')
        }
      },
      {
        field : 'status',
        header: {
          text: this.translateService.instant('GENERAL_SPACE.FIELD.STATUS')
        },
        pipe: 'purchaseOrderStatus'
      },
      {
        classes: 'text-right',
        field  : 'total',
        header : {
          classes: 'text-right',
          text   : this.translateService.instant('GENERAL_SPACE.FIELD.TOTAL')
        },
        pipe: 'globalCurrency',
      },
      {
        component: {
          bindings: {
            outputs: {
              onEditClick: event => this.openPartstechInvoice(event)
            }
          },
          enabled: true,
          factory: EditComponent,
        },
        sortable: false,
      }
    ],
    empty: {
      text: this.translateService.instant('QUERY_DROPDOWN_SPACE.GRID.NO_ORDERS_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : 5,
      pageSizes : [ 5, 10, 25, 50 ],
      queryCount: () => this.partstechService.getOrderCount(this.order)
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: {
      direction: 'desc',
      enabled  : true,
      field    : 'order_number'
    },
  };

  constructor (
    private partstechService : PartstechService,
    private translateService : TranslateService
  ) {}

  getOrders ( params : TableQueryModel ) : ng.IPromise<Array<PurchaseOrderModel>> {
    return this.partstechService.getOrders(this.order)
    .then(orders => {
      this.orders = orders;

      return orders;
    });
  }

  openPartstechInvoice ( order : PurchaseOrderModel ) : void {
    window.location.assign(order.partstech_invoice_url);
  }
}