import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService }    from '@ngx-translate/core';

@Pipe({
  name: 'month'
})
export class MonthPipe implements PipeTransform {

  constructor(
    private translateService : TranslateService
  ) {}

  transform( month : number ) : string {
    const months: any = {
      1 : this.translateService.instant('GENERAL_SPACE.MONTHS.JANUARY'),
      2 : this.translateService.instant('GENERAL_SPACE.MONTHS.FEBRUARY'),
      3 : this.translateService.instant('GENERAL_SPACE.MONTHS.MARCH'),
      4 : this.translateService.instant('GENERAL_SPACE.MONTHS.APRIL'),
      5 : this.translateService.instant('GENERAL_SPACE.MONTHS.MAY'),
      6 : this.translateService.instant('GENERAL_SPACE.MONTHS.JUNE'),
      7 : this.translateService.instant('GENERAL_SPACE.MONTHS.JULY'),
      8 : this.translateService.instant('GENERAL_SPACE.MONTHS.AUGUST'),
      9 : this.translateService.instant('GENERAL_SPACE.MONTHS.SEPTEMBER'),
      10: this.translateService.instant('GENERAL_SPACE.MONTHS.OCTOBER'),
      11: this.translateService.instant('GENERAL_SPACE.MONTHS.NOVEMBER'),
      12: this.translateService.instant('GENERAL_SPACE.MONTHS.DECEMBER')
    };

    return months[month];
  }
}

