import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UIRouter }         from '@uirouter/angular';

import { ApiService }      from '../api/api.service';
import { EditComponent }   from '../table/cell-components/actions/edit.component';
import { InvoiceModel }    from './models/invoice';
import { Table }           from '../table/types/table';
import { TableCountModel } from '../../app-customer/table/models/table-count';

@Component({
  selector   : 'linked-invoices',
  template: require('./linked-invoices.component.html')
})
export class LinkedInvoicesComponent {
  @Input() invoice : InvoiceModel;
  @Input() invoices: Array<InvoiceModel>;
  @Input() type    : string;

  tableSettings : Table = {
    broadcast: `filterLinkedInvoices`,
    columns  : [
      {
        field : 'job_card_number',
        header: {
          text: this.translateService.instant('INVOICE_SPACE.JOB_CARD_NUMBER')
        },
      },
      {
        field : 'post_date',
        header: {
          text: this.translateService.instant('GENERAL_SPACE.FIELD.POST_DATE')
        },
        pipe: 'globalDate'
      },
      {
        field : 'invoice_status',
        header: {
          text: this.translateService.instant('GENERAL_SPACE.FIELD.STATUS')
        },
        pipe: 'invoiceStatus'
      },
      {
        component: {
          bindings: {
            outputs: {
              onEditClick: event => this.view(event)
            }
          },
          enabled: true,
          factory: EditComponent,
        },
        sortable: false,
      }
    ],
    empty: {
      text: this.translateService.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INVOICES_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : 5,
      pageSizes : [ 5, 10, 25, 50 ],
      queryCount: () => this.getCount()
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: {
      enabled: false
    }
  };

  constructor (
    private apiService       : ApiService,
    private router           : UIRouter,
    private translateService : TranslateService
  ) {}

  get () : ng.IPromise<Array<InvoiceModel>> {
    return this.type === 'quote'
      ? this.apiService.get('/invoices/quote_list', this.invoice.id)
      .then(( invoice : InvoiceModel ) => {
        this.invoices = [ invoice ];

        return this.invoices;
      })
      : this.apiService.get('/invoices/job_card_list', this.invoice.id)
      .then(( invoices : Array<InvoiceModel> ) => {
        this.invoices = invoices;

        return this.invoices;
      });
  }

  getCount () : ng.IPromise<TableCountModel> {
    return this.apiService.get(
      this.type === 'quote'
      ? '/invoices/quote_list_count'
      : '/invoices/job_card_list_count',
      this.invoice.id);
  }

  view ( invoice : InvoiceModel ) : void {
    this.router.stateService.go('app.invoice', {
      customer_id: invoice.customer_id ? invoice.customer_id : null,
      id         : invoice.id,
      vehicle_id : invoice.vehicle_id ? invoice.vehicle_id : null
    }, {
      inherit: false
    });
  }
}