import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customerType'
})
export class CustomerTypePipe implements PipeTransform {
  transform( value : string, html : boolean ) : string {
    switch (value) {
      case 'C':
        return html
          ? '<div class="status bg-success"><span>Cash</span></div>'
          : 'Cash';
      case 'A':
        return html
          ? '<div class="status bg-primary"><span>Account</span></div>'
          : 'Account';
    }
  }
}