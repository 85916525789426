import { Component, Inject, Input } from '@angular/core';
import { TranslateService }         from '@ngx-translate/core';
import { UIRouter }                 from '@uirouter/angularjs';

import { CompanyIntegrationsModel } from '../companies/models/company.integrations';
import { MessagesServiceAjs }       from '../messages/messages.service.ajs';
import { TillpaymentsService }      from './tillpayments.service';

@Component({
  selector   : 'wor-tillpayments-settings',
  template: require('./tillpayments.settings.component.html')
})
export class TillpaymentsSettingsComponent{
  @Input() settings : CompanyIntegrationsModel;

  constructor (
    @Inject('$rootScope')
    public $rootScope           : any,
    private messagesService     : MessagesServiceAjs,
    private router              : UIRouter,
    private tillpaymentsService : TillpaymentsService,
    private translateService    : TranslateService
  ) {}

  activate () : void {
    this.tillpaymentsService.activatePos()
    .then(() => {
      this.messagesService.show(this.translateService.instant('JS_SPACE.INTEGRATIONS.ACTIVATED', {
        name: this.translateService.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.TILLPAYMENTS')
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  deactivate () : void {
    this.tillpaymentsService.deactivatePos()
    .then(() => {
      this.messagesService.show(this.translateService.instant('JS_SPACE.INTEGRATIONS.DEACTIVATED', {
        name: this.translateService.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.TILLPAYMENTS')
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }
}
