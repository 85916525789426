import { Injectable }       from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ApiServiceAjs }                    from '../../api/api.service.ajs';
import { Table }                            from '../../table/types/table';
import { TableColumn }                      from '../../table/types/table-column';
import { TableQueryParameters }             from '../../table/types/table-query-parameters';
import { TransactionCenterFiltersModel }    from '../models/transaction-center.filters';
import { TransactionCenterInspectionModel } from '../models/transaction-center.inspection';

@Injectable({
  providedIn: 'root'
})
export class TransactionCenterInspectionsService {
  constructor (
    private apiService       : ApiServiceAjs,
    private translateService : TranslateService
  ) {}

  buildColumns ( filters : TransactionCenterFiltersModel ) : Array<TableColumn> {
    const closedLabel = filters.status === 'A'
      ? 'Date Approved'
      : ( filters.status === 'R'
        ? 'Date Refused'
        : 'Date Finalized'
      );

    const columns : Array<TableColumn> = [
      {
        field : 'inspection_number',
        header: {
          text: this.translateService.instant('INSPECTIONS_SPACE.INSPECTION_NUMBER')
        }
      }
    ];

    switch (filters.status) {
      case 'D':
        columns.push(
          {
            field : 'inspection_date',
            header: {
              text: this.translateService.instant('GENERAL_SPACE.FIELD.DATE')
            },
            pipe: 'globalDate'
          }
        );
        break;
      case 'RA':
        columns.push(
          {
            field : 'request_sent_on',
            header: {
              text: 'Date Requested',
            },
            pipe    : 'globalDate',
            pipeArgs: [ 'mediumDate' ]
          }
        );
        break;
      case 'A':
      case 'F':
      case 'FW':
      case 'R':
      default:
        columns.push(
          {
            field : 'request_closed_on',
            header: {
              text: closedLabel,
            },
            pipe: 'globalDate',
          }
        )
        break;
    }

    columns.push(
      {
        field : 'customer_display_name',
        header: {
          text: this.translateService.instant('CUSTOMER_SPACE.CUSTOMER')
        }
      }
    );

    if (filters.status === 'RA') {
      columns.push(
        {
          field : 'customer_viewed',
          header: {
            text: this.translateService.instant('JS_SPACE.COLUMNS.VIEWED_ON')
          },
          pipe    : 'globalDate',
          pipeArgs: ['MMM d, yyyy h:mm a']
        }
      )
    }
    else {
      columns.push(
        {
          field : 'vehicle_display',
          header: {
            text: this.translateService.instant('VEHICLE_SPACE.VEHICLE')
          }
        }
      )
    }

    return columns;
  }

  getInspections ( filters: TransactionCenterFiltersModel = {}, params : TableQueryParameters = {} ) : ng.IPromise<Array<TransactionCenterInspectionModel>> {
    return this.apiService.get('/transaction_center/inspections', [
      filters.status   || 'D',
      filters.value    || '*',
      params.field     || 'inspection_date',
      params.direction || 'desc',
      params.pageSize  || 11,
      params.offset    || 0
    ])
    .then(( inspections : Array<TransactionCenterInspectionModel> ) => {
      return inspections.map(inspection => {
        inspection.vehicle_display = `${ inspection.vehicle_make || '' } ${ inspection.vehicle_model || '' } ${ inspection.vehicle_plate_number || '' }`;

        return inspection;
      })
    });
  }

  getUpdatedColumns ( tableSettings : Table, filters : TransactionCenterFiltersModel ) : Table {
    const settings = Object.assign({}, tableSettings);

    settings.columns = this.buildColumns(filters);

    return settings;
  }
}