import {
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';

import { DomSanitizer }              from '@angular/platform-browser';
import { NgbCollapse }               from '@ng-bootstrap/ng-bootstrap';
import { UIRouter }                  from '@uirouter/core';

import { PluralizePipe }       from '../shared/pipes/pluralize.pipe';
import { RemindersServiceAjs } from '../reminders/reminders.service.ajs';
import { SessionServiceAjs }   from '../sessions/session.service.ajs';
import { SidebarService }      from './sidebar.service';
import { StockTakeServiceAjs } from '../stock-take/stock-take.service.ajs';

import amsRewardsIcon from 'icons/ams-rewards.svg';
import mitchell1Icon  from 'icons/mitchell1.svg';
import myobIcon       from 'icons/myob.svg';
import napaIcon       from 'icons/napa.svg';
import tiremetrixIcon from 'icons/tiremetrix.svg';
import vvGarageIcon   from 'icons/vv-garage.svg';

@Component({
  selector   : 'wor-sidebar',
  styles: [ require('./sidebar.component.scss') ],
  template: require('./sidebar.component.html')
})
export class SidebarComponent implements OnInit {
  public companyService: any;
  public isSubmenuShowing = false;
  public amsRewardsIcon   = this.sanitizer.bypassSecurityTrustHtml(amsRewardsIcon);
  public mitchell1Icon    = this.sanitizer.bypassSecurityTrustHtml(mitchell1Icon);
  public myobIcon         = this.sanitizer.bypassSecurityTrustHtml(myobIcon);
  public napaIcon         = this.sanitizer.bypassSecurityTrustHtml(napaIcon);
  public tiremetrixIcon   = this.sanitizer.bypassSecurityTrustHtml(tiremetrixIcon);
  public vvGarageIcon     = this.sanitizer.bypassSecurityTrustHtml(vvGarageIcon);

  @ViewChildren(NgbCollapse) menus: QueryList<NgbCollapse>;

  constructor (
    @Inject('$rootScope')
    private $rootScope      : any,
    public pluralizePipe    : PluralizePipe,
    @Inject('remindersApi')
    private remindersService: RemindersServiceAjs,
    private router          : UIRouter,
    private sanitizer       : DomSanitizer,
    public sessionService   : SessionServiceAjs,
    private sidebarService  : SidebarService,
    @Inject('stockTakeApi')
    private stockTakeService: StockTakeServiceAjs
  ) {}

  ngOnInit () : void {
    this.companyService = this.$rootScope.Company;

    this.sidebarService.reset$.subscribe(() => this.closeSubmenus());
  }

  closeSubmenus ( submenu : NgbCollapse | Array<NgbCollapse> = this.menus.toArray() ) : void {
    if ( submenu instanceof Array ) {
      submenu.forEach(menu => menu.collapsed ? null : menu.toggle(false));
    }
    else if (!submenu.collapsed) {
      submenu.toggle(false);
    }

    this.isSubmenuShowing = false;
  }

  isStockTakeAvailable () : boolean {
    return this.companyService.hasWorkshop()
      && this.companyService.isSubscriptionPlan('silver', false)
      && !this.isStockTakeRestricted();
  }

  isStockTakeRestricted () : boolean {
    return this.companyService.isBrand('nrma')
      && this.companyService.hasSecurityRights('create_stocktake');
  }

  navigate ( state = 'app', evt : Event ) : void {
    this.router.stateService.go(state);

    evt.preventDefault();
    evt.stopPropagation();
  }

  sendReminders () : void {
    this.remindersService.send();
  }

  stockTakeQuickAdd ( evt : Event ) : void {
    this.stockTakeService.quickAdd();

    evt.preventDefault();
    evt.stopPropagation();
  }

  toggleSubmenu ( menu : NgbCollapse ) : void {
    this.isSubmenuShowing = !this.isSubmenuShowing;

    menu.toggle();
  }
}
