import { BlockUIModule }           from 'ng-block-ui';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule }            from '@angular/common';
import { FormsModule }             from '@angular/forms';
import { HttpClientModule }        from '@angular/common/http';
import { Injector, NgModule }      from '@angular/core';
import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbPopoverModule,
  NgbTooltipModule
}                                from '@ng-bootstrap/ng-bootstrap';
import { NgPluralizeModule }     from 'ng-pluralize';
import { TranslateModule }       from '@ngx-translate/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { ComponentsModule       } from './components/components.module';
import { ControlsModule         } from './controls/controls.module';
import { DirectivesModule       } from './directives/directives.module';
import { PipesModule            } from './pipes/pipes.module';
import { TranslateConfigService } from '../internationalization/translate-config.service';
import { ValidatorsModule       } from './validators/validators.module';

@NgModule({
  exports: [
    // Angular
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,

    // Third Party
    BlockUIModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPopoverModule,
    NgbTooltipModule,
    TranslateModule,
    UIRouterUpgradeModule,

     // Custom
    ComponentsModule,
    ControlsModule,
    DirectivesModule,
    PipesModule,
    ValidatorsModule
  ],
  imports: [
    // Angular
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,

    // Third Party
    BlockUIModule.forRoot(),
    NgbCollapseModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgPluralizeModule,
    UIRouterUpgradeModule,

    // Custom
    ComponentsModule,
    ControlsModule,
    DirectivesModule,
    PipesModule,
    ValidatorsModule
  ],
  providers: [
    {
      deps      : [ '$injector' ],
      provide   : '$rootScope',
      useFactory: ( $injector : Injector ) => $injector.get('$rootScope')
    },
    {
      deps      : [ '$injector' ],
      provide   : 'Company',
      useFactory: ( $injector : Injector ) => $injector.get('Company')
    }
  ]
})
export class SharedModule {
  constructor (
    private translateConfigService : TranslateConfigService
  ) {
    this.translateConfigService.init();
  }
}
