import { Component, Inject, Input, OnInit, forwardRef } from '@angular/core';
import { BlockUI, NgBlockUI }                           from 'ng-block-ui';
import { NgbActiveModal }                               from '@ng-bootstrap/ng-bootstrap';
import { TranslateService }                             from '@ngx-translate/core';

import { MessagesServiceAjs }        from '../messages/messages.service.ajs';
import { PartstechPartsStatusModel } from './models/partstech.parts-status';
import { PartstechService }          from './partstech.service';
import { PurchaseOrderModel }        from '../../app/purchase-orders/models/purchase-order';
import { Table }                     from '../table/types/table';

@Component({
  selector   : 'wor-partstech-resolve-parts',
  template: require('./partstech.resolve-parts.component.html')
})
export class ResolvePartstechPartsComponent implements OnInit {
  @BlockUI('resolvePartsBlock') block : NgBlockUI;

  @Input() order  : PurchaseOrderModel;
  @Input() status : PartstechPartsStatusModel;

  parts : Array<any>;
  type  : string;

  tableSettings : Table = {
    columns: [],
    empty  : {
      text: this.translateService.instant('GENERAL_SPACE.STATUS.NO_RESULTS')
    },
    pagination: {
      enabled: false
    },
    sorting: {
      enabled: false
    },
    static: true
  };

  constructor (
    public  activeModal     : NgbActiveModal,
    private messagesService : MessagesServiceAjs,
    @Inject(forwardRef(() => PartstechService))
    private partstechService: PartstechService,
    public translateService : TranslateService
  ) {}

  getParts () : void {
    this.block.start();

    this.partstechService.getAvailabilityAndPrices(this.order)
    .then(( status : PartstechPartsStatusModel )  => {
      if ( status.parts_not_ready && status.parts_not_ready.length ) {
        this.parts = status.parts_not_ready;
        this.type  = status.type;
      }
      else {
        this.activeModal.close(true);
      }
    })
    .catch(err => {
      this.messagesService.error(err);
    })
    .finally(() => {
      this.block.stop();
    });
  }

  didPriceChange () : boolean {
    return this.type === 'price_change_check';
  }

  ngOnInit () : void {
    this.parts = this.status.parts_not_ready;
    this.type  = this.status.type;

    this.tableSettings.columns = this.partstechService.getColumns(this.didPriceChange());
  }

  removeItem ( part : any ) : void {
    this.partstechService.removePartFromOrder(part.purchase_order_item_id);
  }
}
