import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  template: require('./print.component.html')
})
export class PrintComponent {
  @Input() record: any;

  @Output() onPrintClick: EventEmitter<any> = new EventEmitter();
}
