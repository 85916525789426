import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  template: require('./edit.component.html')
})
export class EditComponent {
  @Input() record: any;

  @Output() onEditClick: EventEmitter<any> = new EventEmitter();
}
