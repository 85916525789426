import { Component, Input } from '@angular/core';

import { OnlinePaymentsService } from './online-payments.service';

@Component({
  selector   : 'wor-online-payment-success',
  template: require('./online-payment.success.component.html')
})
export class OnlinePaymentSuccessComponent {
  @Input() token : string;

  constructor (
    private onlinePaymentsService: OnlinePaymentsService
  ) {}

  getInvoiceUrl () {
    return this.onlinePaymentsService.getInvoiceLink(this.token);
  }
}
