import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

@Component({
  template: require('./transaction-center.job.row.expand.component.html')
})
export class JobRowExpandComponent {
  @Input() record: any;

  @Output() onEditClick : EventEmitter<any> = new EventEmitter();
  @Output() onLaborClick: EventEmitter<any> = new EventEmitter();
  @Output() onSMSClick  : EventEmitter<any> = new EventEmitter();

  constructor (
    @Inject('$rootScope')
    public $rootScope: any
  ) {}
}