import {
  Component,
  Input
} from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CompanyIntegrationsModel } from '../companies/models/company.integrations';
import { MessagesServiceAjs }       from '../messages/messages.service.ajs';
import { TillpaymentsService }      from './tillpayments.service';

@Component({
  selector   : 'wor-tillpayments-activate-pos',
  template: require('./tillpayments.activate-pos.component.html')
})
export class TillpaymentsActivatePosComponent {
  @Input() settings : CompanyIntegrationsModel;

  constructor (
    public activeModal         : NgbActiveModal,
    private messagesService    : MessagesServiceAjs,
    private tillpaymentsService: TillpaymentsService
  ) {}

  activate () : void {
    this.tillpaymentsService.saveSettings(this.settings)
    .then(() => this.tillpaymentsService.activatePos())
    .then(() => {
      this.activeModal.close(this.settings);
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }
}
