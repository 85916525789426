import { Injectable }       from '@angular/core';
import { NgbModal }         from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { API }                                 from '../api/api.constant.ajs';
import { ApiModel }                            from '../api/models/api.model';
import { ApiServiceAjs }                       from '../api/api.service.ajs';
import { CompanyMessagesModel }                from '../companies/models/company.messages';
import { CustomerModel }                       from '../customers/models/customer';
import { InvoiceModel }                        from '../invoices/models/invoice';
import { OnlinePaymentInvoiceAndSessionModel } from './types/online-payment.invoice-and-session';
import { OnlinePaymentSessionModel }           from './types/online-payment.session';
import { OnlinePaymentSmsRequestComponent }    from './online-payment.sms-request.component';
import { OnlinePaymentSmsRequestModel }        from './types/online-payment.sms-request';

@Injectable({
  providedIn: 'root'
})
export class OnlinePaymentsService {
  _API     : ApiModel = API;
  errorKey = 'onlinePaymentError';
  session  : OnlinePaymentSessionModel;

  constructor (
    private apiService      : ApiServiceAjs,
    private modalService    : NgbModal,
    private translateService: TranslateService
  ) {}

  getInvoiceLink ( token : string ) : string {
    return `${ this._API.url }/invoice/show_by_token/${ token }/true`;
  }

  getInvoiceAndSession ( token : string ) : ng.IPromise<OnlinePaymentInvoiceAndSessionModel> {
    return this.apiService.get('/invoice/show_by_token', [ token, false ]);
  }

  getTaxLabel () : string {
    return this.session.company_settings.tax_name;
  }

  /**
   * TODO: replace this with a call from the invoice
   * TODO: service once the invoice service has been
   * TODO: moved to app-lite.
   *
   * REFERENCE: WOR-3570
   */
  isInvoiceClosed ( invoice : InvoiceModel ) : boolean {
    return invoice.invoice_status === 'C';
  }

  requestSmsPayment ( invoice : InvoiceModel, customer: CustomerModel ) : ng.IPromise<any> {
    if (!customer.mobile) {
      return Promise.reject(this.translateService.instant('COMPANY_SPACE.INTEGRATIONS.ONLINE_PAYMENTS.CUSTOMER_HAS_NO_CONTACT', {
        name: customer.display_name || this.translateService.instant('CUSTOMER_SPACE.CUSTOMER'),
        type: 'mobile'
      }));
    }

    return this.apiService.get('/company_message_settings')
    .then(( messages : CompanyMessagesModel ) => {
      const modal = this.modalService.open(OnlinePaymentSmsRequestComponent);

      modal.componentInstance.companyMessages = messages;
      modal.componentInstance.customer        = customer;
      modal.componentInstance.invoice         = invoice;

      return modal.result;
    });
  }

  sendEmailRequest ( email : any, customer : CustomerModel ) : ng.IPromise<any> {
    return customer.email
      ? customer.address1
        ? this.apiService.post('/system/send_email', {
            attachment_id  : email.invoice_id,
            attachment_type: 'payment_request',
            body           : email.message,
            email_address  : email.email,
            send_invoice   : email.send_invoice,
            subject        : email.subject
          }, 'email')
        : Promise.reject(this.translateService.instant('COMPANY_SPACE.INTEGRATIONS.ONLINE_PAYMENTS.ADD_AN_ADDRESS_TO_CUSTOMER'))
      : Promise.reject(this.translateService.instant('COMPANY_SPACE.INTEGRATIONS.ONLINE_PAYMENTS.CUSTOMER_HAS_NO_CONTACT', {
          name: customer.display_name || this.translateService.instant('CUSTOMER_SPACE.CUSTOMER'),
          type: 'email'
      }));
  }

  sendSmsPaymentRequest ( sms : OnlinePaymentSmsRequestModel ) : ng.IPromise<boolean> {
    return this.apiService.post('/system/send_sms', sms);
  }

  setSessionFromInvoice ( payment : OnlinePaymentInvoiceAndSessionModel ) : OnlinePaymentSessionModel {
    this.session = {
      company_logo       : payment.company_logo,
      company_settings   : payment.company_settings,
      country_settings   : payment.country_settings,
      country_validations: payment.country_validations
    };

    return this.session;
  }
}
