import { NgModule } from '@angular/core';

import { SharedModule }              from '../shared/shared.module';
import { SupportResourcesComponent } from './support.resources.component';

@NgModule({
  declarations: [
    SupportResourcesComponent
  ],
  entryComponents: [
    SupportResourcesComponent
  ],
  imports: [
    SharedModule
  ]
})
export class SupportModule {}
