import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService }    from '@ngx-translate/core';

@Pipe({
  name: 'invoiceStatus',
})
export class InvoiceStatusPipe implements PipeTransform {

  constructor(
    private translateService : TranslateService
  ) {}

  transform( value : string ) {
    const typeMap = {
      C: this.translateService.instant('STATUS_TYPES.CLOSED'),
      O: this.translateService.instant('STATUS_TYPES.OPEN'),
      P: this.translateService.instant('STATUS_TYPES.PROCESSED')
    };

    return typeMap[value] || this.translateService.instant('STATUS_TYPES.OPEN');
  }
}