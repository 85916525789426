import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService }    from '@ngx-translate/core';

@Pipe({
  name: 'unitCondition'
})
export class UnitConditionPipe implements PipeTransform {
  constructor (
    private translateService : TranslateService
  ) {}

  transform( isNew = false ) : string {
    return isNew
      ? this.translateService.instant('DEALERSHIP_SPACE.NEW')
      : this.translateService.instant('DEALERSHIP_SPACE.USED');
  }
}
