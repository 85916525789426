import { Component, Inject, Input } from '@angular/core';
import { BlockUI, NgBlockUI }       from 'ng-block-ui';
import { NgForm }                   from '@angular/forms';
import { TranslateService }         from '@ngx-translate/core';
import { UIRouter }                 from '@uirouter/core';

import { CompanyIntegrationsModel } from '../companies/models/company.integrations';
import { MessagesServiceAjs }       from '../messages/messages.service.ajs';
import { TillpaymentsService }      from './tillpayments.service';

@Component({
  selector   : 'wor-tillpayments-online-settings',
  template: require('./tillpayments.online-settings.component.html')
})
export class TillpaymentsOnlineSettingsComponent {
  @BlockUI('block') block : NgBlockUI;

  @Input() settings: CompanyIntegrationsModel;

  constructor (
    @Inject('$rootScope')
    public $rootScope          : any,
    private messagesService    : MessagesServiceAjs,
    private router             : UIRouter,
    private tillpaymentsService: TillpaymentsService,
    private translateService   : TranslateService
  ) {}

  activate () : void {
    this.tillpaymentsService.activateOnline()
    .then(() => {
      this.messagesService.show(this.translateService.instant('JS_SPACE.INTEGRATIONS.ACTIVATED', {
        name: this.translateService.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.TILLPAYMENTS_ONLINE')
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  deactivate () : void {
    this.tillpaymentsService.deactivateOnline()
    .then(() => {
      this.messagesService.show(this.translateService.instant('JS_SPACE.INTEGRATIONS.DEACTIVATED', {
        name: this.translateService.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.TILLPAYMENTS_ONLINE')
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  save ( form : NgForm ) : void {
    this.block.start();

    this.tillpaymentsService.saveSettings(this.settings)
    .then(() => {
      this.messagesService.show(this.translateService.instant('JS_SPACE.MESSAGES.SAVED.SETTINGS'), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    })
    .finally(() => {
      this.block.stop();
    });
  }
}